import { GetBy } from '../_app/cuchillo/core/Element';
import _Header from '../_app/cuchillo/layout/Header';
import { Power3 } from "gsap";

export default class Header extends _Header {
  static clock = GetBy.class('__clock', this.container)[0];

  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static updateClock() {
    const options = { timeZone: 'Europe/Madrid', hour12: false };
    const now = new Date();
    const timeInBarcelona = now.toLocaleTimeString('es-ES', options);

    this.clock.textContent = timeInBarcelona;
  }

  static loop() {
    super.loop()
    this.updateClock();
  }
}
