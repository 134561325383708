import gsap, { Power1, Power2 } from 'gsap';
// import { SplitText } from 'gsap/SplitText';

// gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__ProjectList extends VScroll_Item {
  _lines;
  _texts = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    // const lines = [...GetBy.selector('.projects .__line', __link)];
    // lines.map(line => {
    // const texts = new SplitText(GetBy.class('__txt', line), { type: 'chars' }).chars;
    // gsap.set(texts, { opacity: 0 });
    // this._texts.push(texts);
    // });

    // const linesInfo = [...GetBy.selector('.info p', __link)];
    // linesInfo.map(line => {
    // const texts = new SplitText(line, { type: 'chars' }).chars;
    // gsap.set(texts, { opacity: 0 });
    // this._texts.push(texts);
    // });

    // const linesExp = [...GetBy.selector('.experiences .__line', __link)];
    // linesExp.map(line => {
    // const texts = new SplitText(GetBy.class('__txt', line), { type: 'chars' }).chars;
    // gsap.set(texts, { opacity: 0 });
    // this._texts.push(texts);
    // });

    // const linesAwa = [...GetBy.selector('.awards .__line', __link)];
    // linesAwa.map(line => {
    // const texts = new SplitText(GetBy.class('__txt', line), { type: 'chars' }).chars;
    // gsap.set(texts, { opacity: 0 });
    // this._texts.push(texts);
    // });

    this.onShow = () => {
      // let delay = 0;
      // this._texts.map(texts => {
      //   texts.map((text, i) => {
      //     gsap.to(text, {
      //       opacity: 1,
      //       duration: .1,
      //       delay: delay + i * 0.005,
      //       ease: Power2.easeOut
      //     });
      //   })
      //   delay += .02;
      // });
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('project-list', ScrollItem__ProjectList);
